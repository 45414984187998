.trip-view {
    &__map {
        width: 100%;
        height: 250px;
        margin-bottom: 20px; }
    .actions-cards {
        justify-content: space-between;
        .action-card {
            background: $lightgray;
            margin: 20px 0;
            flex: 0 0 48%; } } }
.trip-info {
    display: flex;
    flex-wrap: wrap;
    &__time, &__distance {
        padding-right: 10px; }
    &__label {
        font-weight: bold; } }

.trip-map {
    &__container > div {
        width: 100%;
        height: 300px; } }

.trip-breakdown {
    &__header-row, &__row {
        display: flex;
        border-bottom: 1px solid $gray; }
    &__row {
        &:last-child {
            border-bottom: 1px solid transparent; } }
    &__column {
        flex: 1;
        padding: 6px;
        &:not(:last-child) {
            border-right: 1px solid $gray; } }
    &__header-row & {
        &__column {
            font-weight: bold; } } }


@mixin transition($s) {
	-webkit-transition: all $s ease-in-out;
	transition: all $s ease-in-out; }
@mixin box() {
	background: $lightgray;
	box-shadow: 0px 0px 10px -4px #000000; }
@mixin linear-background() {
	background: $primary;
	background: -moz-linear-gradient(-45deg, $primary 0%, $secondary 100%);
	background: -webkit-linear-gradient(-45deg, $primary 0%, $secondary 100%);
	background: linear-gradient(135deg, $primary 0%, $secondary 100%); }
@mixin linear-background-down() {
	background: $primary;
	background: -moz-linear-gradient(to bottom, $primary 0%, $secondary 270%);
	background: -webkit-linear-gradient(to bottom, $primary 0%, $secondary 270%);
	background: linear-gradient(to bottom, $primary 0%, $secondary 270%); }
@mixin psuedo() {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%; }

@mixin animation($animate...) {
	$max: length($animate);
	$animations: "";

	@for $i from 1 through $max {
		$animations: #{$animations + nth($animate, $i)};

		@if $i < $max {
			$animations: #{$animations + ", "}; } }

	-webkit-animation: $animations;
	-moz-animation: $animations;
	-o-animation: $animations;
	animation: $animations; }

@mixin keyframes($animationName) {
	@-webkit-keyframes #{$animationName} {
		@content; }

	@-moz-keyframes #{$animationName} {
		@content; }

	@-o-keyframes #{$animationName} {
		@content; }

	@keyframes #{$animationName} {
		@content; } }

@import "_fonts";

// Fonts
$basicFont: "Roboto", Helvetica, Arial, sans-serif;
$primaryHeaderFont: "Titillium Web", sans-serif;
$secondaryHeaderFont: "Montserrat", sans-serif;

// Theme colors
$primary: #80bc01;
$secondary: darkan(#80bc01, 3%);

// General colors
$white: #ffffff;
$black: #090909;
$blue: #010e21;
$red : #d32525;
$orange: orange;
$green: green;
$purple: purple;
$orange: orange;
$yellow: yellow;

// Grays
$gray: #d2d2d2;
$darkgray: #5a5a5a;
$lightgray: #f2f2f2;
$llightgray: lighten($lightgray, 3%);

$popOverBackground: rgba(0,0,0,0.75);
// Constants
$s: 0.3s;
$radius: 0px;

// Shadows
$shadow: 0px 4px 11px -5px rgba(0, 0, 0, 0.29);
$boxShadow: 0px 0px 30px -15px rgba(0, 0, 0, 0.19);
$formShadow: 0px 20px 65px -5px rgba(0, 0, 0, 0.19);

@import "_mixins";
@import "_animations";

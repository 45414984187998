* {
	&.text-center {
		text-align: center; }
	&.text-left {
		text-align: left; }
	&.text-right {
		text-align: right; }
	&.no-padding {
		padding: 0; }
	&.no-padding-top {
		padding-top: 0; }
	&.no-padding-bottom {
		padding-bottom: 0; }
	&.no-margin {
		margin: 0; }
	&.no-margin-top {
		margin-top: 0; }
	&.no-margin-bottom {
		margin-bottom: 0; } }

.container {
	margin: 0 auto;
	width: 1190px;
	position: relative;
	display: block;
	&.small-box {
		width: 390px; }
	&.mid-box {
		width: 420px; }
	&.big-box {
		width: 600px; }
	&.flex {
		display: flex; }
	&.inline-block {
		display: inline-block; }
	&.block {
		display: block !important; }
	&.full-page {
		min-height: 100vh;
		min-height: -webkit-fill-available; }
	&.error-page {
		min-height: 90vh; } }

.wrapper {
	width: 100%;
	&.top {
		margin-top: 15px; }
	&.inline-block {
		display: inline-block; } }

.absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	-webkit-transform: translate(-50%,-50%);
	-moz-transform: translate(-50%,-50%);
	-o-transform: translate(-50%,-50%); }

.flex-container {
	display: flex;
	flex-wrap: wrap;
	&.top {
		margin-top: 50px; }
	&.column {
		flex-direction: column; }
	&.row {
		flex-direction: row; }
	&.row-reverse {
		flex-direction: row-reverse; }
	&.column-reverse {
		flex-direction: column-reverse; }
	&.align-items-start {
		align-items: flex-start; }
	&.align-items-end {
		align-items: flex-end; }
	&.align-items-baseline {
		align-items: baseline; }
	&.no-wrap {
		flex-wrap: nowrap; }
	.half,.one {
		flex: 1; }
	.two {
		flex: 2; }
	.three {
		flex: 3; }
	.four {
		flex: 4; }
	.five {
		flex: 5; }
	&.self-center, .self-center {
		align-self: center; }
	&.self-top, .self-top {
		align-self: top; }
	&.justify-center, .justify-center {
		justify-content: center; }
	&.justify-start, .justify-start {
		justify-content: flex-start; }
	&.justify-end, .justify-end {
		justify-content: flex-end; }
	&.justify-between, .justify-between {
		justify-content: space-between; } }

.document {
	margin-top: 15px;
	padding: 35px 0;
	h1 {
		font-size: 2em; } }

section {
	&.activity {
		display: none;
		box-shadow: none;
		&.active {
			display: block; } }
	&.margin-top {
		margin-top: 50px;
		&.small {
			margin-top: 35px; }
		&.middle {
			margin-top: 75px; }
		&.high {
			margin-top: 100px; } }
	&.padding {
		padding: 35px 0; } }


.tabs {
	border-bottom: 1px solid $gray;
	@include transition($s);
	&.white {
		border: none;
		li {
			background: $secondary;
			color: $primary;
			border: none;
			&.active {
				background: $white;
				border: none;
				&:hover {
					background: $white;
					border: none;
					color: $primary; } }
			&:hover {
				background: $secondary;
				color: $white;
				border: none; } } }
	&.dash {
		margin-top: 35px;
		ul {
			li {
				border-top-left-radius: $radius;
				border-top-right-radius: $radius; } } }
	&.small-white {
		border: none;
		ul {
			align-items: flex-start;
			li {
				border: none;
				flex: none;
				padding: 0 35px;
				&.active,&:hover {
					border: none;
					background: $white; }
				&.active {
					z-index: 2; } } } }
	&.left {
		box-sizing: border-box;
		border-bottom: none;
		border-right: 1px solid $gray;
		ul {
			display: block; }
		li {
			padding-left: 25px;
			text-align: left;
			width: 100%;
			box-sizing: border-box;
			height: 40px;
			line-height: 40px;
			&.active {
				padding-left: 21px;
				border-bottom: none;
				border-left: 2px solid $primary;
				&:hover {
					border-color: $primary; } }
			&:hover {
				padding-left: 21px;
				border-bottom: none;
				border-left: 2px solid $gray; } } }
	ul {
		list-style: none;
		display: flex;
		padding: 0;
		margin: 0;
		font: {
			family: Montserrat, sans-serif; }
		li {
			flex: 1;
			height: 50px;
			line-height: 50px;
			color: $secondary;
			text-align: center;
			user-select: none;
			@include transition($s);
			&.active {
				background: $white;
				color: $primary;
				border-bottom: 1px solid $primary;
				&:hover {
					border-color: $primary; } }
			&:hover {
				cursor: pointer;
				color: $primary;
				border-bottom: 1px solid $gray; } } } }
.table {
	margin-top: 25px;
	&.small {
		.top-functions {
			flex-direction: column-reverse;
			.flex-container {
				width: 100%;
				flex: 0 0 100%;
				margin-top: 10px; }
			.create-new {
				justify-content: flex-end;
				width: 100%; } } }
	.top-functions {
		display: flex;
		justify-content: space-between;
		margin-top: 10px;
		.search-block {
			flex: 1;
			max-width: 400px; }
		.actions {
			display: flex;
			margin-left: 8px;
			z-index: 100;
			.action {
				margin: 0 4px;
				position: relative;
				.action-input {
					width: 200px; }
				.action-icon {
					width: 40px; }
				.action-menu {
					position: absolute;
					top: 60px;
					left: 10px;
					width: 200px;
					height: auto;
					background: $white;
					padding: 8px 3px;
					display: none;
					box-shadow: 0px 0px 43px -3px rgba(0, 0, 0, 0.30);
					animation: $s pop-out;
					&.show {
						animation: 0.1s pop-in;
						display: block;
						&::after {
							border: solid transparent;
							content: " ";
							height: 0;
							width: 0;
							position: absolute;
							pointer-events: none;
							border-bottom-color: $white;
							border-width: 15px;
							left: 0px;
							top: -30px; } }
					.menu-container {
						max-height: 300px; }
					.action-item {
						padding: 4px 6px;
						font-size: 14px;
						&:hover {
							cursor: pointer; }
						.name {
							&:first-letter {
								text-transform: uppercase; } } }
					input[type = "checkbox"] {
						width: auto;
						margin: 0;
						height: 15px;
						width: 15px;
						padding: 7px;
						margin-right: 6px;
						&:checked::after {
							font-size: 12px;
							line-height: 14px; } }
					label.normal {
						@include transition($s);
						&:hover {
							cursor: pointer;
							color: $primary; } } } } }
		button,input {
			display: block; }
		input {
			width: 100%; } }

	.table-view {
		display: inline-flex;
		flex-direction: column;
		border-radius: $radius;
		margin-top: 15px;
		padding-bottom: 15px;
		width: 100%;
		.table-header, .table-items {
			.table-header-column, .table-column {
				padding: 0 12px;
				word-break: break-all;
				display: inline-flex;
				&.one {
					flex: 1;
					min-width: 120px; }
				&.two {
					flex: 2;
					min-width: 180px; }
				&.three {
					flex: 3;
					min-width: 240px; }
				&.four {
					flex: 4;
					min-width: 300px; }
				&.five {
					flex: 5;
					min-width: 360px; }
				&.image-size {
					width: 38px; }
				&.action {
					flex: 0 0 100px;
					color: $primary;
					font-size: .9em;
					@include transition($s);
					&:hover {
						cursor: pointer;
						color: $secondary; } }
				img {
					width: 20px;
					height: 20px;
					display: block; } }
			.input-group {
				width: auto;
				align-self: center;
				input[type = "checkbox"] {
					margin: 0px 10px 0px 5px; } } }
		.table-header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 15px 0;
			.table-header-column {
				font: {
					size: 1.1em; } } }
		.table-items {
			display: flex;
			flex-direction: column;
			.table-row {
				display: flex;
				justify-content: space-between;
				padding: 8px 0;
				border-bottom: 1px solid $gray;
				&--selected {
					background: $primary;
					color: $white; }
				&-clickable {
					&:hover {
						border-color: $primary;
						cursor: pointer; } } } } } }

.popover {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1001;
	pointer-events: none;
	opacity: 0;
	background: none;
	@include transition($s);
	&.active {
		pointer-events: all;
		opacity: 1; }
	.background {
		background: $popOverBackground;
		width: 100%;
		height: 100%;
		z-index: 0;
		@include animation(popover-fadein $s);
		@include transition($s); }
	.children {
		z-index: 10; } }

.component-stack {
	@for $i from 0 to 20 {
		.rightview:nth-last-child(#{$i + 1}) {
			transform: translateX(-150px * $i); } } }

.topview,.rightview {
	background: $white;
	box-shadow: 0px 0px 65px rgba(0,0,0, 0.25);
	overflow-y: visible;
	@include transition($s);
	&.below {
		.pop-up-container {
			pointer-events: none;
			&::after {
				pointer-events: none;
				opacity: 1;
				z-index: 1001;
				@include transition($s); } } }

	&.hidden {
		display: none; }
	.wrapper.top {
		margin-top: 0px; }
	.pop-up-container {
		position: relative;
		background: $white;
		padding: 0 20px;
		width: 100%;
		min-height: 100%;
		&::after {
			content: " ";
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: $popOverBackground;
			opacity: 0;
			pointer-events: none;
			@include transition($s); } }
	.container {
		width: 100%; }
	.actions {
		display: flex;
		height: 50px;
		justify-content: space-between;
		.close-button {
			height: 20px;
			width: 20px;
			margin: 15px 0; } } }


.topview {
	height: auto;
	width: 100%;
	left: 0;
	top: 0;
	background: $white;
	position: absolute;
	&.animateIn {
		@include animation(slide-in-top $s); }
	&.animateOut {
		transform: translateY(100%);
		box-shadow: none;
		z-index: 1000;
		@include animation(slide-out-top $s); } }

.rightview {
	height: 100vh;
	width: 50%;
	right: 0;
	top: 0;
	position: absolute;
	&.animateIn {
		@include animation(slide-in-right $s); }
	&.animateOut {
		transform: translateX(100%);
		box-shadow: none;
		z-index: 1000;
		@include animation(slide-out-right $s); }
	&.full-width {
		width: 100%; }
	.aside-view {
		min-height: auto; }
	.list-sector {
		background: $lightgray;
		.list-sector {
			background: $white; } } }

@media all and (max-width: 1190px) {
	.container {
		width: 100%;
		padding: 0 10px;
		&.small-box,&.mid-box,&.big-box {
			width: 90%; }
		&.small-box {
			max-width: 390px; }
		&.mid-box {
			max-width: 440px; }
		&.big-box {
			max-width: 600px; } }
	.flex-container {
		.half.full-small {
			flex: initial;
			width: 100%; } }
	.document {
		width: 90%;
		margin: 0 auto; }
	.tabs {
		ul li {
			font-size: .9em;
			height: 45px;
			line-height: 45px; }
		&.white {
			li {
				line-height: 45px; } } }
	.rightview {
		width: 100%; } }

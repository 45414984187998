@import "../vars/_vars";

body {
  margin: 0;
  padding: 0;
  color: $black;
  background: $lightgray;
  font-family: $basicFont;
  line-height: 1.5;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased; }

* {
  box-sizing: border-box;
  -webkit-appearance: none;
  &:focus {
    outline: none; }
  &.center {
    text-align: center !important; }
  &.right {
    text-align: right !important; }
  &.left {
    text-align: left !important; }
  &.big {
    font-size: 4.5em; }
  &.no-margin {
    margin: 0; }
  &.relative {
    position: relative; } }

.background {
  background: $lightgray; }

h1 {
  line-height: 1;
  font: {
    family: $secondaryHeaderFont;
    size: 32px;
    weight: bold; } }
h2 {
  font: {
    family: $basicFont, sans-serif;
    size: 1.3em; }
  &.top {
    color: $secondary;
    position: relative;
    margin: 0;
    margin-bottom: 25px;
    font: {
      family: $secondaryHeaderFont;
      weight: bold;
      size: 1.35em; }
    &::after {
      content: "";
      position: absolute;
      width: 50px;
      height: 3px;
      background: $primary;
      left: 0;
      bottom: -5px; } } }
h3 {
  font: {
    family: $basicFont;
    size: 1.3em;
    weight: lighter; } }

code {
  padding: 20px;
  border: 1px solid $darkgray;
  background: $lightgray;
  border-radius: $radius;
  display: block;
  white-space: pre-wrap; }

fieldset {
  margin: 0;
  padding: 0;
  border: none; }

.fullpage {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  position: relative; }

.no-data, .nothing, .css-gg45go-NoOptionsMessage {
  text-align: center;
  color: $darkgray;
  font: {
    size: 14px;
    style: italic; } }

.not-found-action-box {
  padding: 50px 30px;
  text-align: center;
  &.small {
    padding: 20px 30px;
    .icon {
      height: 75px;
      svg {
        height: 75px; } } }
  .icon {
    margin: 0 auto;
    display: block;
    height: 100px;
    svg {
      height: 100px;
      width: auto;
      fill: $primary; } }
  .no-data {
    font-size: 16px; }
  .action {
    @include transition($s);
    color: $primary;
    &:hover {
      color: $secondary;
      cursor: pointer; } } }

a,.as-link {
  text-decoration: none;
  color: $primary;
  display: inline;
  &:hover {
    color: $secondary;
    cursor: pointer; }
  &:focus, &:active {
    outline: none; } }

button,.button {
  border: none;
  color: $lightgray;
  background: $primary;
  border: 1px solid $primary;
  padding: 5px 25px;
  min-height: 35px;
  border-radius: $radius;
  font: {
    size: 15px;
    family: $secondaryHeaderFont;
    weight: 100; }
  display: inline-block;
  @include transition($s);
  &.center {
    display: block;
    margin: 0 auto; }
  &.full {
    padding: 0;
    width: 100%; }
  &.back, &.dark {
    margin-right: 15px;
    background: $secondary;
    border-color: $secondary;
    &:hover {
      color: $secondary; } }
  &:active,&:focus {
    outline: none; }
  &.loading {
    padding-left: 40px; }
  &:hover {
    cursor: pointer;
    color: $primary;
    background: none;
    @include transition($s); }
  i.fa {
    margin-right: 10px;
    &.behind {
      margin-left: 10px;
      margin-right: 0; } } }

img {
  &.rounded {
    border-radius: 50%; } }

.spinner {
  width: 40px;
  height: 40px;
  background: {
    color: $primary;
    size: cover;
 }    //image: url(../images/Vynwork_gray.svg)
  margin: 50px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out; }

.aside-view {
  display: flex;
  position: relative;
  min-height: 100vh;
  .aside-view-content {
    text-align: center;
    align-self: center;
    .step-form {
      .form {
        display: none;
        &.active {
          display: inline-block; } } }
    form {
      display: inline-block;
      width: 100%;
      text-align: left; }
    .input-container {
      margin: 0; } }
  .aside-view-image {
    flex: 4;
    width: 50%;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    z-index: -1;
    &.linear-gradient {
      @include linear-background(); }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover; } } }

.overlay {
  height: 100vh;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  opacity: 0;
  user-select: none;
  pointer-events: none;
  @include transition($s);
  &.active {
    opacity: 1;
    pointer-events: all;
    user-select: auto; }
  .background {
    background: $black;
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    opacity: 0.85; }
  .children {
    z-index: 10;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .overlay-component {
    background: $white;
    .drag-and-drop {
      p {
        margin: 10px; } } } }
.overhead {
  width: 100%;
  height: 100%;
  background: $lightgray;
  opacity: 0.99;
  position: absolute;
  z-index: 1000;
  position: absolute;
  left: 0;
  top: 0;
  display: none; }

.pager {
  display: flex;
  margin-top: 10px;
  .left,.right {
    height: 35px;
    width: 15px;
    align-self: center;
    display: flex;
    pointer-events: none;
    &:hover {
      cursor: pointer; }
    &.active {
      pointer-events: all;
      svg {
        fill: $black; } }
    div {
      align-self: center;
      width: 100%;
      svg {
        display: block;
        fill: $gray; } } }
  .right {
    transform: rotateZ(180deg); }
  .page-number {
    padding: 5px 8px;
    border-radius: 3px;
    &.active {
      pointer-events: none;
      color: $primary; }
    &:hover {
      cursor: pointer; } } }

.page {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background: $lightgray;
  padding-top: 75px;
  &.pagefade-enter {
    opacity: 0;
    z-index: 1000000; }
  &.pagefade-exit {
    opacity: 0; }
  &.pagefade-enter.pagefade-enter-active {
    opacity: 1;
    transition: all 250ms ease-in; }
  &.clean {
    padding-top: 0;
    footer {
      @include linear-background-down();
      margin-top: 250px;
      &::after {
        width: 100%;
        height: 150px;
        content: "";
        position: absolute;
        top: -149px;
        left: 0;
        z-index: 99;
        background-position-y: -1px;
        background: linear-gradient(177deg, transparent 49%, $primary 50%) no-repeat top/100% 150px, linear-gradient(-5deg, transparent 49%, transparent 50%) no-repeat bottom/100% 150px, transparent; }
      .top {
        .list {
          color: $white;
          a {
            color: $white;
            &:hover {
              color: $secondary; } }
          h1 {
            color: $white;
            &::after {
              background: $white; } } }
        .social {
          i {
            background: $white;
            border-color: $white;
            color: $primary;
            &:hover {
              color: $white;
              background: transparent; } } } }
      .red {
        display: none; }
      .white {
        display: block; }
      i.green {
        color: $white; }
      .bottom {
        color: $white;
        .version {
          color: $white; } } } }
  &.contact {
    footer {
      margin-top: 0px; } } }

.scrollable, .scroll, .pg-viewer-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  &.thick {
    &::-webkit-scrollbar {
      width: 6px; } }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5; }
  &::-webkit-scrollbar {
    width: 2px;
    border-radius: 4px;
    background-color: #F5F5F5; }
  &::-webkit-scrollbar-thumb {
    background-color: $primary; }
  &__x {
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      height: 2px; } } }



.progress-bar {
  width: 100%;
  height: 8px;
  border-radius: 5px;
  .progress {
    background-color: $primary;
    height: 100%;
    margin: 0;
    @include transition($s); } }

.close-button {
  &:hover {
    cursor: pointer; }
  div {
    height: 100%; }
  svg {
    height: 100%;
    width: auto;
    display: block;
    @include transition($s);
    &:hover {
      fill: $primary; } } }

.draggable {
  &:hover {
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab; } }

.action-icons {
  height: 40px;
  width: auto;
  .action-icon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block;
    position: relative;
    z-index: 10;
    flex: 1;
    &:not(:last-child) {
      margin-right: 10px; }
    &.green {
      &::after {
        background: $green; }
      svg {
        fill: $green; } }
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: $primary;
      opacity: 0.1;
      z-index: -1;
      @include transition($s); }
    &:hover {
      cursor: pointer;
      svg {
        fill: $white; }
      &::after {
        opacity: 0.9; } } }
  svg {
    height: 30px;
    width: 30px;
    margin: 5px;
    padding: 5px;
    fill: $primary;
    @include transition($s);
    &:hover {
      fill: $secondary; } } }

.filePreviewer-container {
  height: 100%;
  pointer-events: none;
  .pg-viewer-wrapper, .pg-viewer, .pg-viewer-container {
    pointer-events: none; }
  .pg-viewer-wrapper {
    overflow-y: auto;
    img {
      background: $white; } } }
.files {
  display: flex;
  .file {
    max-width: 230px;
    height: 100px;
    position: relative;
    user-select: none;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: $black;
      opacity: 0;
      pointer-events: none;
      @include transition($s); }
    &:hover {
      cursor: pointer;
      &::after {
        pointer-events: all;
        opacity: 0.3; } }
    &:not(:last-child) {
      margin-right: 3px; }
    img {
      height: 100%;
      width: auto;
      max-width: 100%;
      min-width: 30px;
      object-fit: cover;
      display: block; } } }

.alert-stack {
  position: fixed;
  left: 20px;
  bottom: 40px;
  z-index: 10000;
  .alert {
    background: $white;
    padding: 20px;
    box-shadow: 0px 0px 20px -1px rgba(0,0,0,0.2);
    margin-bottom: 10px;
    max-width: 300px;
    @include transition($s);
    &.animateIn {
      @include animation($s slide-in-left); }
    &.animateOut {
      transform: translateX(-100%);
      box-shadow: none;
      @include animation($s slide-out-left); }
    &.hidden {
      display: none; }
    .description {
      font-size: 14px; }
    &.success {
      border-left: 2px solid $green; }
    &.error {
      border-left: 2px solid $red; }
    &.warning {
      border-left: 2px solid $orange; } } }

.collapsible {
  display: flex;
  padding: 8px 0;
  justify-content: space-between;
  border-bottom: 1px solid $gray;
  &:hover {
    cursor: pointer; }
  &.open {
    svg {
      transform: rotateZ(90deg); } }
  svg {
    transform: rotateZ(-90deg);
    height: 20px;
    fill: $gray; } }

.options-menu {
  position: relative;
  width: 20px;
  height: 20px;
  &--open {
    .options-menu {
      &__options {
        display: block; } } }
  &__dots {
    width: 20px;
    height: 20px;
    line-height: 20px;
    svg {
      fill: $black;
      @include transition($s); }
    &:hover {
      cursor: pointer;
      svg {
        fill: $primary; } } }
  &__options {
    display: none;
    right: 0;
    top: 40px;
    position: absolute;
    background: $white;
    z-index: 10;
    white-space: pre;
    box-shadow: 0px 0px 35px rgba(0,0,0,0.2);
    &::after {
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: $white;
      border-width: 15px;
      right: 0px;
      top: -30px; }
    &__item {
      padding: 8px 32px;
      @include transition($s);
      &:hover {
        cursor: pointer;
        background: $primary;
        color: $white; } } } }

.lds-ripple, .lds-ripple div {
  box-sizing: border-box; }
.lds-ripple {
  display: block;
  position: relative;
  margin: 35px auto;
  width: 80px;
  height: 80px;
  div {
    position: absolute;
    border: 4px solid $primary;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    &:nth-child(2) {
      animation-delay: -0.5s; } } }

@media only screen and (max-width: 1190px) {
  .beta {
    &::before {
      display: none; } } }

@media only screen and (max-width: 660px) {
  .cookie {
    .box {
      width: 90%; }
    .text {
      padding: 25px 5%; } }
  button {
    padding: 0 15px; }
  .hidden-small {
    display: none !important; } }

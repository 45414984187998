@import "../vars/_vars";

input, textarea, .input {
  border: none;
  border-bottom: 1px solid $primary;
  background: none;
  height: 35px;
  width: 100%;
  padding: 0;
  font: {
    size: 1em;
    family: $basicFont; }
  color: $primary;
  position: relative;
  &:hover {
    cursor: pointer; }
  &:disabled {
    opacity: 0.6;
    pointer-events: none; }
  &.hidden {
    visibility: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    left: 0;
    top: 0; }
  &.search {
    width: 100%;
    display: block;
    margin: 0 auto;
    padding: 0 10px;
    border: none;
    box-sizing: border-box;
    background: $white; }
  &[type="color"] {
    border: none; }
  &[type="checkbox"] {
    height: 20px;
    width: 20px;
    float: left;
    margin-right: 100px;
    &:hover {
      cursor: pointer; } }
  &[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    margin: 0;
    border: none;
    background: transparent;
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      background-color: $primary; }
    &:focus {
      outline: none; }
    &::-ms-track {
      width: 100%;
      cursor: pointer;
      background: $primary;
      border-color: transparent;
      color: transparent; }
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 2px;
      cursor: pointer;
      background: $primary;
      border-radius: 2px; }
    &:focus::-webkit-slider-runnable-track {
      background: $primary; }
    &::-moz-range-track {
      width: 100%;
      height: 2px;
      cursor: pointer;
      background: $primary;
      border-radius: 2px; }
    &::-ms-track {
      width: 100%;
      height: 2px;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      border-width: 16px 0;
      color: transparent; }
    &::-ms-fill-lower {
      background: $primary;
      border-radius: 2px; }
    &:focus::-ms-fill-lower {
      background: $primary; }
    &::-ms-fill-upper {
      background: $primary;
      border-radius: 4px; }
    &:focus::-ms-fill-upper {
      background: $primary; }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: $secondary;
      cursor: pointer;
      margin-top: -9px;
      box-shadow: $shadow; }
    &::-moz-range-thumb, &::-ms-thumb {
      box-shadow: $shadow;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: $secondary;
      cursor: pointer; } }
  &[type="submit"] {
    border: none;
    color: $lightgray;
    background: $primary;
    border: 1px solid $primary;
    width: auto;
    padding: 0 25px;
    font-size: 15px;
    border-radius: $radius;
    height: 35px;
    font-family: $secondaryHeaderFont;
    font-weight: 100;
    display: inline-block;
    @include transition($s);
    &:disabled {
      opacity: 0.6; }
    &.round {
      border-radius: 50%; }
    &:hover {
      cursor: pointer;
      color: $primary;
      background: none;
      @include transition($s); } }
  &[type="checkbox"] {
    -webkit-appearance: none;
    background: none;
    border: 1px solid $primary !important;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    margin-top: 6px;
    &:hover {
      cursor: pointer; }
    &:active {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
    &:checked {
      background: $primary;
      &:active {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        background-color: none;
        border: 1px solid $primary;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        color: $primary; }
      &:after {
        content: '\2714';
        font-size: 14px;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: center;
        color: $white;
        pointer-events: none; } } }
  &:focus, &:active {
    outline: none;
    &::after {
      left: 0;
      width: 100%; } }
  &:focus + label:not(.normal), &.has-value + label:not(.normal) {
    top: -15px;
    font-size: 12px;
    color: $primary;
    @include transition($s);
    & + .requirements {
      display: flex;
      @include transition($s); } } }

form,.form, .list-sector {
  width: 100%;
  padding: 0 0 35px 0;
  &.hidden {
    display: none; }
  &.high {
    padding: 100px 0; }
  &.nothing {
    padding: 0;
    margin: 0;
    font-size: 1em; }
  .msg,.srv-validation-message {
    float: left;
    width: 100%;
    text-align: left;
    margin-top: 8px;
    font-size: 13px;
    color: $primary;
    &.bottom {
      margin: 0 0 18px;
      text-align: center;
      font-size: 13px; } }
  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    float: left;
    margin-right: 20px;
    object-fit: cover;
    filter: drop-shadow(0px 0px 6px rgba(0,0,0, 0.1)); }
  .css-yk16xz-control, .css-1pahdxg-control, .css-1fhf3k1-control, .css-1pahdxg-control:hover {
    border: none;
    border-bottom: 1px solid $primary;
    border-color: $primary;
    background: none;
    border-radius: 0;
    box-shadow: none;
    min-height: 35px;
    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      padding: 6px; }
    .css-1uccc91-singleValue {
      color: $primary; }
    input {
      height: 30px; } }
  .css-1fhf3k1-control {
    background: $lightgray; }
  .css-26l3qy-menu {
    border-radius: 0;
    box-shadow: $formShadow; }
  .css-1hwfws3 {
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px; }
  .css-b8ldur-Input {
    margin: 0;
    padding: 0; }
  .css-g1d714-ValueContainer {
    padding: 2px 8px 2px 0; }
  .css-1n7v3ny-option, .css-1n7v3ny-option {
    background: $lightgray; }
  .css-9gakcf-option {
    background-color: $primary; }
  .css-yt9ioa-option, .css-1n7v3ny-option, .css-9gakcf-option {
    &:active, &:focus {
      color: $white;
      background-color: $secondary; } }
  .css-1wa3eu0-placeholder {
    margin: 0; }

  .react-datetime-picker {
    width: 100%;
    color: $primary;
    &__wrapper {
      width: 100%;
      border: none;
      border-bottom: 1px solid $primary; }
    &__button {
      &:enabled {
        &:hover {
          stroke: $primary; } }
      &__icon {
        stroke: $gray; } } }
  .react-calendar {
    &__navigation {
      button {
        color: $black;
        padding: 0;
        border-radius: 0; } }
    &__tile {
      color: $black;
      min-height: auto;
      border-radius: 0;
      font-size: 13px;
      &--active {
        &:enabled {
          &:hover, &:focus {
            background: $primary;
            color: $white; } } } }
    &__button {
      svg {
        stroke: hsl(0,0%,80%);
        fill: hsl(0,0%,80%); } } } }

textarea {
  min-height: 250px;
  height: auto;
  display: block;
  padding: 10px 0;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  &.small {
    min-height: 120px; } }

.input-container {
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  &.fixed-width {
    width: 650px; }
  &.middle {
    max-width: 350px;
    width: 350px; } }

.input-group {
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-top: 23px;
  display: inline-block;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0%;
    height: 1px;
    background: $secondary;
    z-index: 1;
    @include transition($s); }
  &:focus,&.active {
    &::after {
      width: 100%;
      left: 0; } }
  &.highlight-box {
    padding: 20px;
    background: $white; }
  &.no {
    margin-top: 0; }
  &.more {
    margin-top: 35px; }
  &.eighty {
    width: 80%; }
  &.ninety {
    width: 90%; }
  &.sixty {
    width: 60%; }
  &.half {
    width: 50%; }
  &.fourty {
    width: 40%; }
  &.twothird {
    width: 66%; }
  &.third {
    width: 33%; }
  &.fourth {
    width: 25%; }
  &.fifth {
    width: 20%; }
  &.tenth {
    width: 10%; }
  &.eighty,&.half,&.third,&.fourth,&.fifth,&.tenth,&.ninety,&.sixty,&.fourty,&.twothird {
    float: left; }
  &.right {
    text-align: right; }
  &.center {
    text-align: center; }
  &.left {
    text-align: left; }
  p {
    text-align: left;
    font-size: 12px;
    &.first-time {
      padding-left: 130px; }
    &.no-padding {
      padding-left: 0; } }
  .text {
    text-align: left;
    font-size: 1em; }
  h1 {
    text-align: left;
    font: {
      size: 1em !important; } }
  h1,h2 {
    margin: 0;
    color: $secondary; }
  h2 {
    &.top {
      margin: 10px 0 25px; } }
  label {
    position: absolute;
    left: 0;
    top: 8px;
    font: {
      family: $basicFont;
      weight: 400; }
    color: $primary;
    pointer-events: none;
    user-select: none;
    @include transition($s);
    &.picture {
      pointer-events: all;
      position: static;
      float: none;
      border: none;
      color: $white;
      background: $primary;
      border: 1px solid $primary;
      margin-top: 25px;
      font-size: 15px !important;
      padding: 0 25px;
      height: 35px;
      box-sizing: border-box;
      color: $white;
      line-height: 31px;
      font-family: $secondaryHeaderFont;
      display: inline-block;
      @include transition($s);
      &:hover {
        cursor: pointer;
        color: $primary;
        background: none; } }
    &.normal {
      pointer-events: all;
      font-style: normal;
      padding-left: 35px;
      float: left;
      text-align: left;
      color: $primary;
      font-weight: normal;
      line-height: 1;
      &:hover {
        cursor: pointer; }
      &.gray {
        color: $darkgray; } }
    &.remember {
      pointer-events: all;
      font-style: normal;
      margin-top: -10px;
      width: 100%;
      display: inline-block;
      right: 0;
      float: right;
      text-align: right;
      color: $black;
      font-family: $secondaryHeaderFont;
      &:hover {
        cursor: pointer; } }
    a {
      font: {
        weight: 600; } } } }

.input-group {
  .select-option, .select-value {
    display: flex;
    img {
      height: 25px;
      width: 25px;
      border-radius: 50%;
      margin-right: 10px; } } }

.list-sector {
  background: $white;
  padding: 25px;
  margin-top: 18px;
  .list-actions {
    display: flex;
    height: 30px;
    justify-content: space-between;
    h2 {
      margin: 0; }
    .close-button, .go {
      height: 14px;
      width: 14px;
      margin: 8px 0;
      svg {
        display: block; } }
    .go {
      transform: rotateZ(180deg);
      height: 22px;
      width: 22px;
      margin: 4px 0 0 20px; } } }
.actions-input {
  .list-sector {
    width: auto;
    width: 45%; } }

.step-form {
  display: inline-block;
  max-width: 650px;
  width: 100%; }

.drag-and-drop {
  user-select: none;
  margin: 0;
  &.dragging {
    p {
      border-style: solid;
      background: $green;
      border-color: $green;
      color: $white; } }
  &.rejected {
      p {
        background: $red;
        border-color: $red; } }
  &:hover {
    cursor: pointer; }
  div:focus {
    outline: none;
    p {
      border-color: $secondary; } }
  p {
    margin: 10px 0 0;
    padding: 40px 35px;
    text-align: center;
    border: 1px dashed $primary;
    color: $black;
    font: {
      size: .9em; }
    @include transition($s);
    &:hover {
      color: $primary; } } }

.image-cropper {
  &.avatar {
    width: 100%;
    height: 300px;
    position: relative; }
  &.banner {
    width: 100%;
    height: 300px;
    position: relative;
    .close-button {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1000;
      svg {
        fill: $white;
        &:hover {
          fill: $primary; } } } } }

.uploadedFiles {
  .file {
    margin: 10px 0;
    background: $llightgray;
    display: flex;
    img {
      height: 40px;
      width: auto;
      max-width: 40px;
      object-fit: contain;
      padding-right: 10px; }
    .info {
      flex: 1;
      .name {
        color: $darkgray;
        span {
          font-size: 10px;
          padding-left: 20px;
          color: $darkgray; } }
      .state {
        display: flex;
        font-size: 10px;
        div {
          align-self: center; }
        span {
          padding-right: 10px; } } }
    .actions {
      width: 30px;
      padding-top: 10px;
      align-self: center;
      text-align: center;
      i {
        color: $primary;
        @include transition($s);
        &:hover {
          cursor: pointer;
          transform: scale(1.05); } } } } }

.signature {
  border: 1px solid $primary;
  display: block; }

.drag-and-drop {
  label {
    position: static;
    pointer-events: all;
    width: 100%; } }

.DayPicker-Month {
  margin: 0 !important; }
.DayPicker, .DayPicker-wrapper {
  &:focus {
    outline: none; } }
.DayPickerInput {
  display: block !important;
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside), .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: $primary; } }


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  &.disabled {
    opacity: 0.4;
    pointer-events: none; }
  input {
    opacity: 0;
    width: 0;
    height: 0; }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    @include transition($s);
    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      @include transition($s); }
    &.round {
      border-radius: 34px;
      &:before {
        border-radius: 50%; } } }
  input {
    &:checked + .slider {
      background-color: $primary; }
    &:focus + .slider {
      box-shadow: 0 0 1px $primary; }
    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px); } } }

.or {
  width: 100%;
  height: 50px;
  display: block;
  text-align: center;
  margin: auto;
  line-height: 50px;
  color: $secondary;
  position: relative;
  &::before, &::after {
    width: 30%;
    height: 1px;
    background: $secondary;
    content: "";
    position: absolute;
    margin-top: 25px; }
  &::before {
    left: 15%; }
  &::after {
    right: 15%; } }

.bank-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 210px;
  width: 520px;
  margin: 35px auto 0;
  .bank-option {
    width: 90px;
    height: 90px;
    overflow: hidden;
    display: flex;
    background: white;
    position: relative;
    border-top: 2px solid transparent;
    @include transition($s);
    &:hover {
      cursor: pointer; }
    &.selected,&:focus, &:hover {
      outline: none;
      border-top: 2px solid $primary; }
    &.selected,&:focus {
      border-color: $secondary;
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $secondary;
        z-index: 100;
        @include transition($s); } }
    img {
      justify-content: center;
      align-self: center;
      width: 90%;
      margin-left: 5%;
      height: auto; } } }


.rating {
  border: none;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  &.small {
    > {
      label:before {
        font-size: 20px; } } }
  > {
    input {
      display: none; }
    label:before {
      margin: 5px;
      user-select: all;
      font: {
        size: 30px;
        weight: 900; }
      display: inline-block;
      content: "\2605"; }
    .half:before {
      content: "\f089";
      position: absolute; }
    label {
      pointer-events: all;
      color: $gray !important;
      position: static; }
    input:checked ~ label {
      color: #FFD700 !important; } }
  &:not(:checked) > label:hover {
    color: #FFD700 !important;
    cursor: pointer;
    ~ label {
      color: #FFD700 !important; } }
  > {
    input:checked {
      + label:hover, ~ label:hover {
        color: #FFED85 !important; } }
    label:hover ~ input:checked ~ label, input:checked ~ label:hover ~ label {
      color: #FFED85 !important; } } }

.stepper {
  width: 100%;
  color: $secondary;
  margin: 0 0 35px;
  font: {
    size: .9em; }
  display: flex;
  .step {
    flex: 1;
    text-align: left;
    border-bottom: 2px solid $gray;
    user-select: none;
    margin-right: 30px;
    @include transition($s);
    &:last-child {
      margin-right: 0px; }
    &.disabled {
      opacity: 0.6; }
    &:not(:disabled):hover {
      cursor: pointer;
      transfrom: scale(1.05); }
    .number {
      background: $primary;
      color: $white;
      border-radius: 50%;
      display: inline-block;
      height: 30px;
      width: 30px;
      text-align: center;
      line-height: 30px; }
    .text {
      margin: 10px 0; }
    &.active {
      border-bottom: 2px solid $primary; } } }

@media only screen and (max-width: 550px) {
  form {
    margin-top: 0;
    padding: 15px 0;
    display: inline-block;
    .input-container {
      width: 100%; }
    .banner-image {
      width: 300px;
      height: 168px; }
    .input-group {
      &.small-full {
        width: 100%; }
      &.small-eighty {
        width: 80%; }
      &.small-sixty {
        width: 60%; } }
    input[type="submit"] {
      padding: 0 15px; } }
  .bank-options {
    height: 500px;
    width: 310px; } }

@media only screen and (max-width: 650px) {
  .input-container {
    width: 100%; } }

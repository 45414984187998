.goods-form-element {
    @include transition($s);
    &:hover {
        cursor: pointer;
        transform: scale(1.05);
        .go svg {
            fill: $primary; } }
    .value-list {
        display: flex;
        .value-list-item {
            margin-right: 8px; } } }

.goods-view {
    .adr-view {
        padding-left: 20px; } }

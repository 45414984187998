.business {
	.wrapper.top {
		margin-top: 65px; } }
.side-business,.top-business {
	.picture {
		position: relative;
		.action-icons {
			position: absolute;
			margin: 0;
			bottom: 10px;
			right: 0;
			.action-icon {
				&::after {
					opacity: 1; }
				&:hover {
					&::after {
						background: $secondary; } }
				svg {
					fill: $white; } } } }

	.headline {
		font: {
			size: 20px; }
		margin-top: 10px; }
	.location {
		margin-top: 5px;
		color: $darkgray;
		font: {
			size: 20px; } }
	.banner {
		object-fit: cover;
		&.editable {
			&:hover {
				opacity: 0.7;
				cursor: pointer; } } } }

.top-business {
	padding-bottom: 25px;
	.banner {
		width: 100%;
		height: 282px;
		background: $secondary;
		@include transition($s); }
	.info {
		padding-left: 30px; }
	.action-icons {
		margin-top: 30px; }
	.picture {
		width: 150px;
		height: 150px;
		display: block;
		margin: -88px 0 0;
		img {
			border-radius: 50%;
			background: $white;
			border: 3px solid $white;
			height: 100%;
			width: 100%;
			display: block; } }
	.name {
		padding: 10px 0 0;
		line-height: 38px;
		font: {
			family: 'Titillium Web';
			size: 38px;
			weight: normal; } } }

.header {
  width: 100%;
  height: 65px;
  line-height: 65px;
  z-index: 1000;
  position: fixed;
  top: 0;
  box-shadow: 0 0 20px -5px rgba(0,0,0,0.15);
  background: $lightgray;
  &.clear {
    background: none;
    box-shadow: none;
    border: none;
    position: absolute; }
  & > * {
    vertical-align: top; }
  .container {
    height: 100%; }
  &__search {
    margin-left: 15px;
    position: relative;
    input {
      padding: 8px 20px;
      background: $white;
      border-radius: 40px;
      border: 1px solid $gray;
      &:focus {
        border-color: $primary; } }
    &__icon {
      svg {
        position: absolute;
        top: 50%;
        right: 10px;
        height: 15px;
        width: 15px;
        fill: $gray;
        transform: translateY(-50%); } } }
  &__search-result-container {
    width: 100%;
    background: $white;
    border: 1px solid $gray;
    border-radius: $radius;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-top: 0;
    position: absolute;
    overflow: scroll;
    top: 64px;
    left: 0;
    box-shadow: 0px 30px 53px -31px rgba(0, 0, 0, 0.2);
    &::before, &::after {
      content: " ";
      position: absolute;
      height: 30px;
      width: 100%; }
    &::before {
      top: 0;
      left: 0;
      background: linear-gradient(to bottom, $white, transparent); }
    &::after {
      bottom: 0;
      left: 0;
      background: linear-gradient(to top, $white, transparent); } }
  &__search-result {
    width: 100%;
    max-height: calc(100vh - 65px);
    padding: 20px;
    line-height: normal;
    .result {
      display: flex;
      padding: 8px 0;
      border-bottom: 1px solid $gray;
      @include transition($s);
      &:last-child {
        border-bottom: 1px solid transparent; }
      &:hover {
        cursor: pointer;
        border-color: $primary; }
      div {
        flex: 1;
        min-width: 120px;
        &.one {
          flex: 1;
          min-width: 120px; }
        &.two {
          flex: 2;
          min-width: 180px; }
        &.three {
          flex: 3;
          min-width: 240px; }
        &.four {
          flex: 4;
          min-width: 300px; }
        &.five {
          flex: 5;
          min-width: 360px; }
        &:not(:first-child) {
          margin-left: 16px; } } } }
  nav {
    width: 250px;
    height: auto;
    position: absolute;
    display: none;
    background: $white;
    color: $black;
    z-index: 1200;
    top: 80px;
    right: 0;
    border-radius: $radius;
    border-top-right-radius: 0px;
    border: 1px solid $gray;
    box-shadow: 0px 10px 33px -1px rgba(0,0,0,0.2);
    animation: $s pop-out;
    &.show {
      animation: 0.1s pop-in;
      display: block; }
    &::after {
      bottom: 100%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: $white;
      border-width: 15px;
      right: 0px; }
    .content-box {
      padding: 10px 0;
      max-height: calc(100vh - 80px);
      width: 100%; }

    .closebutton {
      position: absolute;
      width: 25px;
      height: 25px;
      top: -10px;
      right: 5px;
      z-index: 100;
      display: none;
      &::after {
        content: "\00d7";
        position: absolute;
        color: $black;
        top: 0;
        left: 0;
        font-size: 25px; } }
    .info {
      width: 100%;
      line-height: 25px;
      padding-left: 15px;
      box-sizing: border-box;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid $gray;
      .name {
        font: {
          size: 18px; }
        text-align: left;
        color: $primary; }
      .email {
        font-size: 13px;
        text-align: left; } }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      hr {
        margin: 5px 0;
        border: none;
        border-top: 1px solid $gray; }
      a {
        color: $black; }
      li {
        width: 100%;
        padding-left: 15px;
        box-sizing: border-box;
        height: 35px;
        line-height: 35px;
        text-align: left;
        font-size: 14px;
        &:hover {
          cursor: pointer;
          padding-left: 11px;
          background: $lightgray;
          border-left: 2px solid $primary;
          @include transition($s); } } } }
  .listbutton {
    width: 30px;
    height: 65px;
    line-height: 65px;
    color: $primary;
    position: relative;
    margin-left: 7px;
    display: inline-block;
    @include transition($s);
    svg {
      fill: $primary;
      width: 25px;
      height: 65px;
      line-height: 65px;
      display: block;
      @include transition($s); }
    &:hover {
      cursor: pointer;
      color: $secondary;
      @include transition($s);
      svg {
        fill: $secondary; } } }
  .logo {
    width: auto;
    height: 49px;
    margin: 8px 0;
    object-fit: contain;
    svg {
      width: 100%;
      height: 49px; } }
  img {
    display: block;
    position: relative;
    width: 65px;
    height: 65px;
    &.middle {
      margin: 0 auto;
      position: absolute;
      left: calc(50% - (65px / 2)); }
    &.left {
      float: left; } }
  .create {
    margin-right: 20px; }
  .menu {
    display: flex;
    justify-content: space-around;
    margin-left: 10px;
    .icon {
      width: 45px;
      height: 65px;
      position: relative;
      color: $primary;
      display: block;
      padding: 10px 0;
      border-top: 2px solid transparent;
      @include transition($s);
      &.active {
        border-top: 2px solid $primary; }
      &:hover {
        cursor: pointer;
        svg {
          fill: $secondary; } }
      .button {
        height: auto;
        display: block;
        position: relative;
        text-align: center; }
      svg {
        fill: $primary;
        width: 25px;
        height: 25px;
        display: block;
        margin: 10px auto; }
      &.notifications.new,&.messages.new {
        &::before {
          content: attr(data-content);
          position: absolute;
          min-width: 10px;
          width: auto;
          height: 15px;
          background: $secondary;
          color: white;
          bottom: 55%;
          right: 5px;
          line-height: 15px;
          font: {
            size: 10px;
            weight: 600; }
          z-index: 100;
          text-align: center;
          pointer-events: none;
          border-radius: 15px;
          padding: 0 2px;
          @include transition($s); } }
      &.messages.new {
        &::before {
          right: 0px; } } }

    .messages,.notifications {
      .box {
        display: none;
        position: absolute;
        width: 400px;
        padding-top: 50px;
        top: 77px;
        margin-left: -365px;
        background: $white;
        box-shadow: 0px 10px 33px -1px rgba(0,0,0,0.15);
        border-radius: $radius;
        border-top-right-radius: 0px;
        border: 1px solid $gray;
        z-index: 1200;
        color: $black;
        animation: $s pop-out;
        &.show {
          animation: 0.1s pop-in;
          display: block; }
        &::after {
          bottom: 100%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-bottom-color: $white;
          border-width: 15px;
          right: 0px; }
        .n_header {
          text-align: left;
          padding: 10px 10px 0;
          height: 50px;
          line-height: 30px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          box-sizing: border-box;
          font: {
            size: 18px; }
          .actions {
            float: right;
            a {
              margin-left: 10px; } }
          a {
            font: {
              size: 13px; } } }
        .item-list {
          max-height: 450px;
          overflow-y: scroll; }
        .nothing {
          margin: 10px 0; }
        .more {
          width: 100%;
          height: 25px;
          line-height: 25px;
          font-size: 13px;
          text-align: center;
          &:hover {
            text-decoration: underline; } }
        .message {
          width: 100%;
          border-bottom: 1px solid $gray;
          color: $black;
          text-align: left;
          box-sizing: border-box;
          padding: 10px;
          position: relative;
          display: flex;
          &.new {
            background: $gray; }
          &:hover {
            background: $lightgray;
            &::after {
              position: absolute;
              content: "";
              height: 100%;
              width: 2px;
              background: $primary;
              left: 0;
              top: 0; } }
          .left {
            flex: 2;
            line-height: 15px;
            font-size: 14px;
            min-height: 0px;
            padding: 0;
            h1 {
              height: auto;
              display: block;
              height: 25px;
              line-height: 25px;
              margin: 0px;
              padding-top: 5px;
              font: {
                size: 17px; }
              text-align: left;
              span {
                position: relative; }
              .unread {
                font-size: 10px;
                position: absolute;
                right: -24px;
                top: 5px;
                background: $primary;
                color: $white;
                border-radius: 16px;
                min-width: 16px;
                width: auto;
                height: 16px;
                display: inline-block;
                text-align: center;
                line-height: 16px; }
              .date {
                font: {
                  size: 11px;
                  style: italic;
                  weight: normal; }
                float: right; } }
            p {
              margin: 6px 0;
              color: $darkgray; } }
          img {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            align-self: center;
            justify-content: flex-end;
            filter: none;
            margin-right: 10px;
            object-fit: cover; } } } } }
  .logout,.create {
    width: auto;
    height: 35px;
    margin: 15px 0; }
  &.full {
    @include linear-background();
    .listbutton {
      color: $lightgray;
      &:hover {
        color: $secondary; } }
    .menu .icon {
      color: $lightgray; }
    .logout,.create {
      background: $lightgray;
      border: 2px solid $lightgray;
      color: $primary;
      &:hover {
        background: none;
        color: $lightgray; } } } }

.bottom-menu {
  width: 100%;
  height: 42px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: $primary;
  z-index: 1000;
  display: none;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0px 0px 10px -4px rgba(0,0,0,0.4);
  .section {
    flex: 1;
    height: 42px;
    position: relative;
    text-align: center;
    &.active {
      background: $secondary; }
    svg {
      height: 26px;
      width: 26px;
      display: block;
      fill: $white;
      margin: 8px auto; } } }

@media all and (max-width: 1190px) {
  .header {
    .container .wrapper {
      display: flex; } } }
@media all and (max-width: 660px) {
  body {
    padding-bottom: 50px; }
  .header {
    &::before {
      content: " ";
      position: absolute;
      right: initial;
      top: 0;
      right: 0;
      z-index: 1001;
      width: 100vw;
      height: 100vh;
      pointer-events: none;
      background: rgba(0,0,0,0.9);
      opacity: 0; }
    &.show {
      &::before {
        opacity: 1; } }
    &__search {
      display: none; }
    .bottom-menu {
      display: flex; }
    .menu {
      .icon {
        display: none; } }
    .create {
      padding: 0 15px; }
    .dashboard {
      display: none; }
    .listbutton {
      width: 30px;
      &::before {
        text-align: center; } }
    .container .wrapper {
      display: flex; }
    .logo {
      margin-left: 0; }
    nav {
      background: none;
      width: 100%;
      top: 0;
      left: initial;
      margin-left: 0;
      right: 0;
      height: 100vh;
      box-shadow: none;
      position: fixed;
      padding: 0;
      background: transparent;
      border-radius: 0;
      border: 0;
      @include animation(none);
      &::after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: none;
        background: rgba(0,0,0,0.7);
        z-index: -1; }
      &.show {
        &::after {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border: none;
          background: rgba(0,0,0,0.7);
          z-index: -1; } }
      .closebutton {
        display: block; }
      .content-box {
        max-height: 100%;
        height: 100%;
        width: 250px;
        margin: 0 0 0 auto;
        background: $white;
        border-left: 1px solid $gray;
        transform: translateX(0);
        @include animation($s menu-slide-in); } } } }

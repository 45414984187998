.dashboard-section {
    display: flex;
    .dashboard-main {
        flex: 1; }
    .dashboard-detail {
        flex: 3;
        background: $white;
        padding: 25px;
        h1 {
            margin-top: 0; }
        input.search {
            background: $lightgray; } } }

.actions-cards {
    display: flex;
    .action-card {
        flex: 1; } }

.action-card {
    padding: 20px;
    border-radius: 10px;
    border: 1px solid $gray;
    &--actual {
        @include animation(live-pulse 2s infinite); }
    &__name {
        font: {
            weight: bold; } }
    &__timewindow {
        color: $darkgray;
        font-size: 14px; }
    &__location {
        margin-top: 15px; } }


@import "vars/_vars";

.cookie, .select-pop-over {
    width: 100%;
    height: 100vh;
    min-height: -webkit-fill-available;
    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    .box {
        position: absolute;
        width: 600px;
        height: auto;
        display: inline-block;
        background: $lightgray;
        box-shadow: 0px 0px 140px rgba(0, 0, 0, 0.2);
        padding: 35px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: cookie 900ms; }
    .title {
        width: 100%;
        text-align: center;
        color: $primary;
        font: {
            size: 2em; }
        weight: 100; }
    .text {
        width: 100%;
        height: auto;
        text-align: center;
        padding: 5px 30px 15px;
        font-size: 17px;
        box-sizing: border-box;
        color: $black; }
    .choice {
        width: 100%;
        height: 50px;
        text-align: center;
        line-height: 50px;
        margin-top: 10px;
        button {
            width: auto;
            margin: 0 5px;
            &:active, &:focus {
                outline: none; }
            &:hover {
                cursor: pointer;
                background: none;
                color: $primary; } }
        a,.as-link {
            padding: 0 25px; } } }

.notification-list {
    .notification {
        color: $black;
        border-bottom: 1px solid transparent;
        padding: 15px;
        display: flex;
        align-self: flex-start;
        @include transition($s);
        &:hover {
            cursor: pointer;
            background: $lightgray; }
        &:not(:last-child) {
            border-bottom: 1px solid $gray; }
        img {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 10px; }
        .content {
            flex: 1; }
        .title {
            font: {
                size: 24px; }
            display: flex;
            justify-content: space-between; }
        .message {
            color: $darkgray; }
        .date {
            line-height: 28px;
            font: {
                size: 13px;
                style: italic; } } } }
.person {
    width: 100%;
    height: 250px;
    border-bottom: 1px solid $gray; }

.contact-info {
    list-style: none;
    font: {
        size: 2em; }
    .social {
        margin-top: 25px;
        i {
            margin-right: 13px;
            background: $black;
            color: $white;
            padding: 15px;
            border-radius: 50%;
            @include transition($s);
            &:hover {
                box-shadow: 0px 2px 15px -3px rgba(0,0,0,0.4);
                @include transition($s);
                transform: scale(1.05);
                cursor: pointer; } } } }

.start {
    display: flex;
    min-height: 600px;
    height: 100vh;
    .visual {
        flex: 0 0 50%;
        overflow: hidden;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: $primary;
            opacity: 0.3; }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(-45deg, $primary, transparent); }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; }
        .content {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 80%;
            transform: translate(-50%, -50%);
            z-index: 10;
            h1,h2,h3,h4,h5,h6,a {
                display: block; }
            h1 {
                color: $white;
                z-index: 100;
                margin: 0;
                font: {
                    size: 6vw;
                    weight: bold; } }
            a {
                color: $white;
                text-decoration: underline;
                margin-top: 25px;
                font: {
                    size: 2vw; }
                &:hover {
                    text-decoration: none; } } } }
    .action {
        flex: 0 0 50%;
        position: relative;
        &.center {
            text-align: center; }
        &.info {
            h1 {
                margin-bottom: 18px; } }
        .content {
            display: none;
            text-align: center; }
        form {
            width: 50%;
            min-width: 350px;
            margin: 0 auto;
            padding: 0; }
        a.center {
            margin: 40px auto 0;
            display: block;
            text-align: center; } }
    img {
        &.logo {
            display: block;
            width: 85px; } } }

@media only screen and(max-width: 960px) {
    .start {
        .visual {
            flex: 0 0 100%;
            .content {
                display: none; } }
        .action {
            position: absolute;
            width: 90%;
            height: 100%;
            left: 5%;
            background: $white;
            .absolute-center {
                width: 80%; }
            .content {
                display: block;
                text-align: left;
                h1 {
                    font-size: 45px;
                    margin-bottom: 15px; } }
            form {
                width: 100%;
                min-width: auto; } } } }


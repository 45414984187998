.vehicle-detail {
    &__box {
        padding: 20px;
        margin-top: 35px;
        background: $white;
        position: relative;
        border-radius: $radius;
        box-shadow: $boxShadow;
        border: 1px solid $gray;
        margin-top: -1px;
        border-top-left-radius: 0;
        &--messages, &--map {
            padding: 0;
            overflow: hidden; } }
    &__map {
        width: 100%;
        height: 400px;
        border-radius: $radius; }
    .chat {
        height: 500px;
        .new-message {
            border-top: 1px solid $gray; }
        .chat-main {
            .messages {
                max-height: 380px; } }
        .actions {
            display: block; } }
    .actions-cards {
        justify-content: space-between;
        .action-card {
            background: $lightgray;
            margin: 0 0 20px 0;
            flex: 0 0 48%; } }
    .tabs {
        .tab, .tab:hover {
            border: 1px solid transparent;
            border-bottom: 1px solid $gray;
            &.active {
                border: 1px solid $gray;
                border-bottom: 1px solid $white;
                z-index: 2; } }
        ul {
            li {
                padding: 0 25px; } } } }

.vehicle-trips, .vehicle-activities {
    &__trip,&__activity {
        padding: 5px 0;
        border-bottom: 1px solid $gray;
        &:not(:first-child) {
            margin-top: 10px; }
        &:last-child {
            border-bottom: 1px solid transparent; } }
    &__trip {
        padding: 30px 0; }
    &__map {
        width: 100%;
        height: 250px;
        z-index: 0;
        margin-bottom: 30px; }
    &__name {
        display: flex;
        line-height: 40px;
        font: {
            weight: bold;
            size: 20px; }
        &--active {
            color: $primary; } }
    &__location {
        font-size: 18px; }
    &__date {
        margin-left: 8px;
        color: $darkgray;
        font: {
            weight: normal;
            size: 14px; } }
    .actions-bar {
        margin-top: 15px; } }

.vehicle-trips {
    &__name {
        font-size: 28px; } }

.vehicle-activities {
    max-height: 500px;
    &__type {
        padding: 4px 7px;
        border-radius: 15px;
        background: $primary;
        color: $white;
        margin-left: 5px;
        align-self: center;
        font-size: 12px;
        line-height: 14px; }
    &__date {
        margin-left: 0; } }

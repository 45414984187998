.location-view {
    &__map {
        width: 100%;
        height: 300px;
        z-index: 0;
        .default-marker {
            fill: $primary; } } }

.location-info {
    margin: 20px 0; }

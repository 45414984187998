.fleet-vehicle {
    font-size: 20px;
    display: flex;
    &__number {
        font-weight: bold; }
    &__name {
        span {
            @include transition($s);
            &:hover {
                color: $primary;
                cursor: pointer; } } } }

.settings {
	.container {
		padding-top: 0px;
		h1 {
			margin: 0; }
		.__box {
			width: 100%;
			display: flex; }
		.tabs {
			flex: 1.5;
			position: relative; }
		section {
			flex: 4.5;
			min-height: 520px;
			box-sizing: border-box;
			display: none;
			&.active {
				display: block; }
			h1 {
				color: $black; } }
		form {
			text-align: left;
			h1 {
				margin: 0;
				text-align: left; } } }
	.sessions {
		.session {
			padding: 10px;
			background: $lightgray;
			&:not(:first-child) {
				margin-top: 10px; }
			h2, h3 {
				font-size: 1em;
				margin: 0; }
			h3 {
				&:not(:first-child) {
					margin-left: 8px; } } } } }

@media all and (max-width: 660px) {
	.settings {
		.container {
			.__box {
				display: block; }
			.settings-box {
				display: none;
				min-height: auto; }
			.select-box {
				width: 100%;
				.new {
					display: none; } }
			section {
				height: auto;
				min-height: auto;
				padding: 0; } } } }
